import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import videoDesktop from "../assets/AmmaadDesktop.mp4";
import videoTablet from "../assets/AmmabadTablette.mp4";
import videoMobile from "../assets/AmmaadMobile.mp4";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faXTwitter,
  faInstagram,
  faYoutube,
  faDiscord,
} from "@fortawesome/free-brands-svg-icons";

const Main = () => {
  const [videoSrc, setVideoSrc] = useState(videoDesktop);

  useEffect(() => {
    const updateVideoSrc = () => {
      if (window.innerWidth <= 768) {
        setVideoSrc(videoMobile);
      } else if (window.innerWidth <= 1024) {
        setVideoSrc(videoTablet);
      } else {
        setVideoSrc(videoDesktop);
      }
    };

    updateVideoSrc(); // Set initial video
    window.addEventListener("resize", updateVideoSrc); // Update video on resize

    return () => {
      window.removeEventListener("resize", updateVideoSrc);
    };
  }, []);

  return (
    <div className="main">
      <div className="overlay"></div>
      <video src={videoSrc} autoPlay loop muted />
      <div className="header-link">
        <Helmet>
          <script
            src="https://www.kisskissbankbank.com/fr/projects/amma-baad/external-banner.js"
            type="text/javascript"
          ></script>
        </Helmet>
      </div>
      <div className="content">
        <a
          href="https://www.facebook.com/ammabaad.music?locale=fr_FR"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faFacebook} />
        </a>
        <a
          href="https://twitter.com/ammabaad_music"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faXTwitter} />
        </a>
        <a
          href="https://www.instagram.com/ammabaad.music/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faInstagram} />
        </a>
        <a
          href="https://www.youtube.com/channel/UCAgxNErPWJpua--ISBV7dEA"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faYoutube} />
        </a>
        <a
          href="https://discord.gg/pjMGM6ueRE"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faDiscord} />
        </a>
      </div>
    </div>
  );
};

export default Main;
